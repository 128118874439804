<template>
  <div class="transition u-wrapper-panel u-z-front">
    <div class="transition__over u-wrapper-panel u-bg-main-color"/>
    <div class="transition__content u-wrapper-panel u-bg-second-color"/>
  </div>
</template>

<script>
export default {
  props: {
    duration: {
      type: Number,
      default: 1500
    }
  },

  computed: {
    durationString () {
      return String(this.duration * .001) + 's'
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/easings'

duration = v-bind(durationString)

.transition
  z-index 1000

  .transition__over
    transition transform duration ease-in-out-quint
    transform translate3d( 0, 0, 0 )
  .transition__content
    transition transform duration .4s ease-in-out-quint
    transform translate3d( 0, 0, 0 )

  &-enter-active
    .transition__over
      transition transform duration ease-in-out-quint
      transform translate3d( 0, 0, 0 )
    .transition__content
      transition transform duration .4s ease-in-out-quint
      transform translate3d( 0, 0, 0 )
  &-leave-active
    .transition__content
      transition transform duration ease-in-out-quint
      transform translate3d( 0, 0, 0 )
    .transition__over
      transition transform duration .4s ease-in-out-quint
      transform translate3d( 0, 0, 0 )

  &-enter-from
    .transition__content, .transition__over
      transform translate3d(0, -100%, 0)
  &-leave-to
    .transition__content, .transition__over
      transform translate3d(0, 100%, 0)
</style>
