import { api } from '@/store/interfaces/apiCraft'
import { pages } from '@/assets/data'
// import Vue from 'vue'

let totalQuestions = 0

export default {
  namespaced: true,

  state: {
    keypoints: [],
    homepage: {},
    endpage: {},
    pages: [],
    job: {},
    categories: [],
    globalLoaded: false,
    totalQuestions: 0,
    loaded: false
  },

  getters: {
    homepage: state =>{
      return state.homepage
    },
    endpage: state =>{
      return state.endpage
    },
    pages: state =>{
      return state.pages
    },
    page: state => name =>{
      return state.pages.find(page => page.name === name)
    },
    config: state =>{
      return state.config
    },
    tutorials: state =>{
      return state.config.tutorials
    },
    keypoints: state => {
      return state.keypoints
    },
    keypoint: state => (arg1, arg2) => {
      const keypoint = state.keypoints.find(keypoint => keypoint.slug === arg1)

      if (keypoint && arg2) {
        return keypoint.keypoints.find(keypoint => keypoint.slug === arg2)
      }

      return keypoint
    },
    totalQuestions: state  => {
      return state.totalQuestions
    },
    nextKeypoint: (state, getters) => (arg1, arg2) => {
      const keypoint = getters.keypoint(arg1, arg2)
      return state.keypoints[keypoint.index + 1]
    },
    categories: state => {
      return state.categories
    },
    job: state => {
      return state.job
    },
    globalLoaded: state =>{
      return state.globalLoaded
    },
    totalKeypoints: state =>{
      let total = 0
      state.keypoints.forEach(k => {
        total += k.keypoints.length ? k.keypoints.length : 1
      })

      return total
    },
    totalCompleteKeypoints: state =>{
      let total = 0
      state.keypoints.forEach(k => {
        let completeChild = 0
        k.keypoints.forEach(key => {
          completeChild += key.isComplete ? 1 : 0
        })
        total += completeChild !== 0 ? completeChild : k.keypoints.isComplete ? 1 : 0
      })

      return total
    },
    mostChosenCategory: state => {
      // first we create an object where property are cat ids
      let categories = state.categories.reduce((accumulator, current) => {
        accumulator[current.id] = 0
        return accumulator
      }, {})

      // in this object we increment values
      state.keypoints.forEach(keypoint => {
        keypoint.questions.forEach(question => {
          question.catChosen.forEach(cat => {
            categories[cat]++
          })
        })
      })

      // we find the most voted cat
      let most = {id: '', nbr: 0}
      for (const cat in categories) {
        if (categories[cat] > most.nbr) {
          most.id = cat
          most.nbr = categories[cat]
        }
      }

      return state.categories.find(cat => cat.id === most.id)
    },
    loaded: state =>{
      return state.loaded
    }
  },

  actions: {
    global ({ commit, state, dispatch }) { 
      const promise = Promise.all([dispatch('homepage'), dispatch('endpage'), dispatch('pages'), dispatch('config'), dispatch('categories')])

      promise.then(() => {
        commit('globalLoaded', true)
      })

      return promise
    },

    homepage ({ commit, state }) {
      return new Promise(resolve => {
        const { request, cache } = api.getHomepage()
        cache.then(res => {
          if (res !== null) {
            commit('homepage', res)
            resolve()
          }
        })

        request.then(res => {
          commit('homepage', res)
          resolve()
        })
      })
    },

    endpage ({ commit, state }) {
      return new Promise(resolve => {
        const { request, cache } = api.getEndpage()
        cache.then(res => {
          if (res !== null) {
            commit('endpage', res)
            resolve()
          }
        })

        request.then(res => {
          commit('endpage', res)
          resolve()
        })
      })
    },

    pages ({ commit, state }) {
      return new Promise(async resolve => {
        for (const page of pages) {
          if (page.active) {
            const { request, cache } = api.getPage(page)

            request.then(res => {
              commit('pages', {...page, ...res})
            })
            await request
          }
        }
        resolve()
      })
    },

    config ({ commit, state }) {
      return new Promise(resolve => {
        const { request, cache } = api.getConfig()
        cache.then(res => {
          if (res !== null) {
            commit('config', res)
            resolve()
          }
        })

        request.then(res => {
          commit('config', res)
          resolve()
        })
      })
    },

    keypoints ({ commit, state }) {
      return new Promise(resolve => {
        const { request, cache } = api.getEntries()
        cache.then(res => {
          // if (res !== null) {
          //   commit('keypoints', formatKeypoints(res))
          //   commit('loaded', true)
          // }
        })

        request.then(res => {
          commit('keypoints', formatKeypoints(res))
          commit('totalQuestions', totalQuestions)
          commit('loaded', true)
          resolve()
        })
      })
    },

    categories ({ commit, state, dispatch }) {
      return new Promise(resolve => {
        const { request, cache } = api.getCategories()
        cache.then(res => {
          // if (res !== null) {
          //   commit('categories', res)
          //   commit('loaded', true)
          // }
        })

        request.then(res => {
          dispatch('catCount', formatCategories(res))
          resolve()
        })
      })
    },

    catCount ({ commit, state }, categories) {
      let promises = []
      categories.forEach(cat => {
        const { request, cache } = api.getCategoryCount(cat.id)
        promises.push(
          new Promise(resolve => {
            // cache.then(res => {
            //   cat.count = res
            //   resolve()
            // })
            request.then(res => {
              cat.count = res
              resolve()
            })
          })
        )
      })

      return Promise.all(promises).then(() => {
        commit('categories', categories)
        // commit('loaded', true)
      })
    },

    filterKeypoints ({ commit, state }, categories) {
      return new Promise(resolve => {
        const { request, cache } = api.getJobsFiltered(categories.map(cat => cat.id))
        cache.then(res => {
          // if (res !== null) {
          //   commit('keypointsFiltered', formatKeypoints(res))
          //   commit('loaded', true)
          // }
        })

        request.then(res => {
          commit('filter', res)
          resolve()
          // commit('loaded', true)
        })
      })
    },

    filterJob ({ commit, state }, categories) {
      return new Promise(resolve => {
        const { request, cache } = api.getJobFiltered(categories)
        cache.then(res => {
          // if (res !== null) {
          //   commit('keypointsFiltered', formatKeypoints(res))
          //   commit('loaded', true)
          // }
        })

        request.then(res => {
          commit('job', res)
          resolve()
          // commit('loaded', true)
        })
      })
    }
  },

  mutations: {
    homepage (state, payload) {
      state.homepage = payload
    },
    endpage (state, payload) {
      state.endpage = payload
    },
    pages (state, payload) {
      state.pages.push(payload)
    },
    config (state, payload) {
      state.config = payload
    },
    globalLoaded (state, payload) {
      state.globalLoaded = payload
    },
    loaded (state, payload) {
      state.loaded = payload
    },
    keypoints (state, payload) {
      state.keypoints = payload
    },
    totalQuestions (state, payload) {
      state.totalQuestions = payload
    },
    filter (state, payload) {
      const entries = payload.map(cat => cat.id)
      state.keypoints.forEach((keypoint, i) => {
        keypoint.keypoints.forEach((key, j) => {
          key.isActive = entries.includes(key.id)
        })
        keypoint.isActive = entries.includes(keypoint.id)
      })
    },
    categories (state, payload) {
      state.categories = payload
    },
    completed (state, args) {
      if (args.question !== undefined) {
        args.keypoint.questions[args.question].isComplete = true
        args.keypoint.questions[args.question].catChosen = args.answer.categories.map(cat => cat.id)
      }
      else {
        args.keypoint.isComplete = true
      }

      // args.answer.categories.forEach(aCat => {
      //   const cat = state.categories.find(sCat => sCat.id === aCat.id)

      //   cat.nbrChosen++
      // })
    },
    job (state, job) {
      state.job = job
    },
    restart (state) {
      state.keypoints.forEach(keypoint => {
        keypoint.questions.forEach(question => {
          question.catChosen.splice(0, question.catChosen.length)
        })
      })
    }
  }
}

// We can do recursive stuff
// but for functionnal issues
// is is way more evolutive
// and easy no to
function formatKeypoints (keypoints) {
  let globalEpisodeIndex = 0
  let globalQuestionIndex = 0
  keypoints.forEach((keypoint, i) => {
    keypoint.keypoints.forEach((key, j) => {
      globalQuestionIndex = formatQuestions(key.questions, globalQuestionIndex)
      key.globalIndex = globalEpisodeIndex
      key.isComplete = false
      key.isActive = true
      globalEpisodeIndex++
    })
    globalQuestionIndex = formatQuestions(keypoint.questions, globalQuestionIndex)

    keypoint.index = i
    keypoint.isComplete = false
    keypoint.isActive = true
  })

  return keypoints
}

function formatQuestions (questions, globalIndex) {
  questions.forEach((question, k) => {
    question.answers.forEach((answer, l) => {
      answer.index = l
    })
    question.index = k
    question.globalIndex = globalIndex
    question.isComplete = false
    question.catChosen = []
    globalIndex++
  })

  totalQuestions = globalIndex > totalQuestions ? globalIndex : totalQuestions

  return globalIndex
}

function formatCategories (categories) {
  // categories.forEach(cat => {
  //   cat.nbrChosen = 0
  // })
  return categories
}