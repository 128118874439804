
<template>
  <component
    :is="url || to.name ? 'div' : 'button'"
    class="t-button u-inline-block u-cursor-pointer u-center t-cta"
    :class="{
      'is-ghost': ghost,
      'is-main': main,
      'is-icon': icon,
      'is-second': second,
      'focus': focus
    }"
  >
    <template v-if="url !== ''" >
      <a v-if="isUrlAbsolute" @focus="focus=true" @blur="focus=false" :href="url" target="_blank" class="u-relative" :class="metaClass">
        <slot/>
      </a>
      <router-link v-else @focus="focus=true" @blur="focus=false" :to="url" class="u-relative" :class="metaClass">
        <slot/>
      </router-link>
    </template>
    <router-link v-else-if="to.name" @focus="focus=true" @blur="focus=false" :to="to" class="u-relative" :class="metaClass">
      <slot/>
    </router-link>
    <span v-else class="u-relative" :class="metaClass">
      <slot/>
    </span>
  </component>
</template>

<script>
import Button from './Button'

export default {
  mixins: [Button],

  computed: {
    metaClass () {
      let classs =  this.icon ? 'u-row u-center u-middle' : ''

      classs += ' t-cta'

      return classs + ' u-relative ' + this.innerClass
    }
  },

  data () {
    return {
      focus: false
    }
  },

  props: {
    icon: {
      type: Boolean,
      default: false
    },
    main: {
      type: Boolean,
      default: false
    },
    second: {
      type: Boolean,
      default: false
    },
    ghost: {
      type: Boolean,
      default: false
    },
    innerClass: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/mixins/viewport-sizer'
@import '~@/styles/settings/easings'

.t-button
  z-index 0
  position relative
  color: $colors.white
  background-color transparent
  padding 15px 30px
  // viewportSizer('padding-left', 30, 1920, 20px, 40px)
  // viewportSizer('padding-right', 30, 1920, 20px, 40px)
  // viewportSizer('padding-top', 30, 1920, 10px, 20px)
  // viewportSizer('padding-bottom', 30, 1920, 10px, 20px)

  &:after
    content ''
    z-index -1
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    border-radius 100px
    background-color: $colors.main-color
    transition: transform .5s ease-in-out-quart
  &:focus:after, &.focus:after
    border: solid 3px $colors.white
  
  &.is-icon svg
    width 1.5em
    height 1.5em

    +mq($from: 'tablet')
      width 1em
      height 1em

  &.is-main
    color: $colors.white

    &:after
      background-color: $colors.main-color

    &:focus:after, &.focus:after
      border: solid 2px $colors.white

  &.is-second
    color: $colors.main-color

    &:after
      background-color: $colors.second-color
    &:focus:after, &.focus:after
      border: solid 3px $colors.main-color

  &.is-ghost
    color: $colors.white

    &:after
      border: solid 2px $colors.white
      background-color: transparent
    &:focus:after, &.focus:after
      border: solid 4px $colors.white

  &.is-ghost.is-main
    color: $colors.main-color

    &:after
      border: solid 2px $colors.main-color
      background-color: transparent
    &:focus:after, &.focus:after
      border: solid 4px $colors.main-color

  &.is-ghost.is-second
    color: $colors.second-color

    &:after
      border: solid 2px $colors.second-color
      background-color: transparent
    &:focus:after, &.focus:after
      border: solid 4px $colors.second-color
  
+mq($from: 'tablet')
  .t-button:hover:after
        transform scale(1.1, 1.1)

@keyframes shiny
  0%
    transform: translateZ(0) scale(.9)
    opacity 0
  40%
    opacity 1

  100%
    transform: translateZ(0) scale(1.3)
    opacity 0
</style>