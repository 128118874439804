<template>
  <div class="question u-full-width u-relative u-pad-md u-overflow-y-scroll u-left u-invisible-scroll">
    <!-- QUESTION HEADER -->
    <div>
      <div v-if="!$device.isDesktop" class="question__count u-radius u-inline-block t-h4">
        {{ data.index + 1 }} / {{ totalQuestions }}
      </div>
    </div>
    <h4 class="t-h3 u-main-color u-marg-t-sm u-white--sm">
      {{ data.title }}
    </h4>

    <!-- ANSWERS -->
    <div class="u-marg-t-sm">
      <app-answer
        class="u-full-width u-marg-t-sm"
        v-for="answer in data.answers"
        :name="slugify(data.title)"
        :label="answer.label"
        :isSelecting="answer.index === selectedAnswer"
        @select="onSelect(answer)"
      />
    </div>

    <!-- CTA VALIDATE -->
    <!-- <div v-if="selectedAnswers.length && !isAnswer" class="u-row u-center">
      <app-button @click="onValidate" class="u-marg-t-md">
        Valider
      </app-button>
    </div> -->
  </div>
</template>

<script>
import AppAnswer from '@/components/common/AppAnswer'
import AppButton from '@/components/common/AppButton'
import slugify from 'slugify'

export default {
  components: {
    AppAnswer,
    AppButton
  },

  props: {
    data: {
      type: Object,
      default: () => {return {}}
    },
    totalQuestions: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      selectedAnswer: -1,
      isAnswer: false
    }
  },

  methods: {
    slugify (str) {
      return slugify(str).toLowerCase()
    },

    onSelect (answer) {
      if (this.isAnswer) { return }

      this.isAnswer = true
      this.selectedAnswer = answer.index
      this.$ga.send('gameplay', 'answer', '', this.data.slug + '-' + answer.label)
      // this.$store.commit('data/completed', { keypoint: this.data, question: answer.index })

      setTimeout(() => {
        this.$emit('answer', answer)
      }, 1500)
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.question
  max-height 100%
  border-radius 20px
  background-color: rgba(#4236FF, .15)
  
  +mq($until: 'tablet')
    background-color: rgba(#4236FF, .85)

  // kind of shitty but left do this
  
  &__count
    padding 7px 10px
    border 2px solid $colors.white
    color $colors.white

  &__answer-info
    &:before
      content ''
      opacity .2
      position absolute
      height 100%
      width 100%
      left 0
      top 0
      border-radius 20px
      background-color $colors.white

</style>