<template>
  <transition name="t-fader">
    <div
      v-if="isVisible && $route.name !== 'Introduction'"
      class="header u-full-width u-row u-left u-middle u-pad-t-sm u-pad-b-lg u-pad-x-md u-main-color"
    >
      <div class="u-row u-middle u-w9of12">
        <router-link title="homepage" class="header__logo u-block u-relative u-z-back" :to="{name: 'Introduction'}">
          <h1 class="u-block u-full-width">
            <img src="/images/logo.jpg" alt="Total Vivatech" class="u-full-width u-block">
          </h1>
        </router-link>
        <template v-if="$device.isDesktop">
          <h2 v-if="$route.name === 'Keypoint'" class="t-h3 u-row u-middle u-pad-x-md u-block">
            {{ superKeypoint.title }}
          </h2>
          <h2 v-else-if="$route.name === 'End'" class="t-h3 u-row u-middle u-pad-x-md u-block">
            Tu pourrais bien être...
          </h2>
        </template>
      </div>
      <div class="u-w3of12 u-row u-middle u-right u-relative u-z-back">
        <app-button-round
          v-if="!$device.isDesktop"
          @click="$emit('menu')"
          aria-label="menu"
          svg="menu"
          :stroke="true"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import AppButtonRound from '@/components/common/AppButtonRound'

export default {
  components: {
    AppButtonRound
  },

  data () {
    return {
      isVisible: true
    }
  },

  mounted () {
    this.$hub.on('header:hidden', this.onHidden)
    this.$hub.on('header:visible', this.onVisible)
  },

  beforeUnmount () {
    this.$hub.off('header:hidden', this.onHidden)
    this.$hub.off('header:visible', this.onVisible)
  },

  methods: {
    onVisible () {
      this.isVisible = true
    },
    onHidden () {
      this.isVisible = false
    }
  },

  computed: {
    superKeypoint () {
      return this.$store.getters['data/keypoint'](this.$route.params.super)
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.header
  position absolute
  top 0
  left 0
  background: linear-gradient(0deg, rgba($colors.white,0) 0%, $colors.white 40%, $colors.white 100%)

  &__logo
    width 80px

    +mq($until: 'tablet')
      width 60px
</style>