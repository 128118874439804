import { createApp, mixin } from 'vue'
import { createRouter } from 'vue-router'

import { createI18n } from 'vue-i18n'

import { store } from '@/store'
import { router } from './router'
import { emitter } from '@/utils/emitter'

import Local from '@/plugins/Local'
import Device from '@/plugins/Device'
import Hub from '@/plugins/Hub'
import Offset from '@/plugins/Offset'
// import Dom3d from '@/plugins/Dom3d'
import GoogleAnalytics from '@/plugins/GoogleAnalytics'

import VueAuth0Plugin from 'vue-auth0-plugin'

import messages from '@/locales'

import AppImage from '@/components/common/AppImage'
import AppImages from '@/components/common/AppImages'
import AppButton from '@/components/common/AppButton'

import extendedComponent from '@/mixins/extendedComponent'

import App from './App.vue'

class VueArtwork {
  constructor () {
    this.app = createApp(App)

    // router
    this.router = createRouter(router)
    this.app.use(this.router)

    // i18n
    this.i18n = createI18n({
      locale: 'en',
      messages
    })
    this.app.use(this.i18n)

    // VueX
    this.app.use(store)

    // Mixin
    this.app.mixin(extendedComponent)

    // Handmade Plugins
    this.app.use(Local)
    this.app.use(Device)
    this.app.use(Hub)
    this.app.use(Offset)
    // this.app.use(Dom3d)
    this.app.use(GoogleAnalytics)

    // AUTH0
    // this.app.use(VueAuth0Plugin, {
    //   domain: process.env.VUE_APP_AUTH_DOMAIN,
    //   audience: process.env.VUE_APP_AUTH_AUDIENCE,
    //   client_id: process.env.VUE_APP_AUTH_CLIENT,
    //   redirect_uri: process.env.VUE_APP_AUTH_REDIRECT
    // })

    this.app.component('app-image', AppImage)
    this.app.component('app-images', AppImages)
    this.app.component('app-button', AppButton)

    this.app.mount('#app')

    this.router.beforeEach(this.beforeGuard)
  }

  beforeGuard (to, from, next) {
    if (!from.name && to.name === 'End') {
      next({name: 'Introduction'})
      return
    }

    emitter.emit('transition:on')
    setTimeout(() => {
      next()
      setTimeout(() => {
        emitter.emit('transition:off')
      }, 400)
    },1000)

  }
}

new VueArtwork()
