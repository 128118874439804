export const endpage = `
  id
  title
  subTitle: sousTitre
  image {
    ... on default_Asset {
      id
      url
      alt
    }
  }
  wysiwyg
  cta: CTA {
    text: col1
    url: col2
  }
`

export const basePage = `
  id
  title
  wysiwyg
  image {
    ... on default_Asset {
      id
      url
      alt
    }
  }
`

export const config = `
  id
  title
  stMainColor
  stSecondColor
  tutorials: stTutorial {
    ... on stTutorial_tutorial_BlockType {
      id
      titre
      text
      image {
        ... on default_Asset {
          id
          url
          alt
        }
      }
    }
  }
`

export const categories = `
  id
  title
  shortTitle
  url
  video {
    ... on default_Asset {
      id
      url
      alt
    }
  }
  CTAs: ctaList {
    text: col2
    url: col1
  }
  content: stContentAnswerCategory {
    ... on stContentAnswerCategory_subTitle_BlockType {
      id
      title: subTitle
      type: typeHandle
    }
    ... on stContentAnswerCategory_image_BlockType {
      id
      image {
        ... on default_Asset {
          id
          url
          alt
        }
      }
      type: typeHandle
    }
    ... on stContentAnswerCategory_text_BlockType {
      id
      text
      type: typeHandle
    }
  }
`

export const homepage = `
  id
  title
  subtitle: sousTitre
  image {
    ... on default_Asset {
      id
      url
      alt
    }
  }
  multipleTexts {
    ... on multipleTexts_multipleTexts_BlockType {
      id
      text
    }
  }
  video {
    ... on default_Asset {
      id
      url
      alt
    }
  }
`

export const keypoint = `
  id
  title
  slug
  subtitle: sousTitre
  position {
    x
    y
    z
  }
  look {
    x
    y
    z
  }
  zoom
  image {
    ... on default_Asset {
      id
      url
      alt
    }
  }
  video {
    ... on default_Asset {
      id
      url
      alt
    }
  }
  multipleTexts {
    ... on multipleTexts_multipleTexts_BlockType {
      id
      text
    }
  }
  questions: stQuestionEntry {
    ... on stQuestion_stQuestionEntry_Entry {
      id
      title
      slug
      textWin
      textLose
      media: media {
        ... on default_Asset {
          id
          url
          alt
        }
      }
      cta: CTA {
        text: col1
        url: col2
      }
      answers: stAnswer {
        ... on stAnswer_stAnswerMatrix_BlockType {
          id
          value: trueFalse
          label: answer
          categories: answerCategory {
            id
            slug
          }
        }
      }
    }
  } 
`