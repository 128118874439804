import { emitter } from '@/utils/emitter'

import { sounds } from '@/assets/data'

export class Audio {
  constructor (autodestroy = false) {
    this.play = this.play.bind(this)
    this.pause = this.pause.bind(this)
    this.destroy = this.destroy.bind(this)
    this.reset = this.reset.bind(this)

    this.player = document.createElement("audio")
    this.player.pause()
    this.player.autoplay = false
    this.isPlaying = false
    this._volume = 1
    this.volume = 1
    this.autodestroy = autodestroy

    if (this.autodestroy) {
      this.player.addEventListener('ended', this.destroy)
    }

    emitter.on('mute', this.onMute)
  }

  set src (src) {
    this.player.src = src

    if (this.isPlaying) {
      this.player.play()
    }
  }

  set volume (volume) {
    this._volume = volume
    this.player.volume = volume * .5
  }

  get volume () {
    return this._volume
  }

  onMute (isMute) {
    if (isMute) {
      this.player.volume = 0
    }
    else {
      this.volume = this._volume
    }    
  }

  play () {
    this.player.play()
    this.isPlaying = true
  }

  pause () {
    this.player.pause()
    this.isPlaying = false
  }

  reset () {
    this.pause()
    this.player.currentTime = 0
  }

  destroy () {
    this.player.src = ''
    emitter.off('mute', this.onMute)

    if (this.autodestroy) {
      this.player.removeEventListener('ended', this.destroy)
    }
  }
}

export const audio = new Audio()
