import { createWebHistory } from 'vue-router'
import { AuthenticationGuard } from 'vue-auth0-plugin'

import Styleguide from './views/Styleguide'
import Introduction from './views/Introduction'
import Keypoint from './views/Keypoint'
import End from './views/End'

import messages from './locales'

const routes = [
  {
    path: 'styleguide',
    name: 'Styleguide',
    component: Styleguide,
    meta: {
      isFull: false
    }
  },
  {
    path: '',
    name: 'Introduction',
    component: Introduction,
    meta: {
      isFull: true
    }
  },
  {
    path: 'end',
    name: 'End',
    component: End,
    meta: {
      isFull: true
    }
  },
  {
    path: ':super',
    name: 'Keypoint',
    component: Keypoint,
    meta: {
      isFull: true
    }
  },
  {
    path: ':super/:slug',
    name: 'SubKeypoint',
    component: Keypoint,
    meta: {
      isFull: true
    }
  },
  // {
  //   path: '',
  //   name: 'Introduction',
  //   component: Introduction,
  //   meta: {
  //     isFull: true
  //   },
  //   beforeEnter: AuthenticationGuard
  // },
  // {
  //   path: '/:pathMatch(.*)*',
  //   name: 'NotFound', 
  //   component: NotFound
  // }
]

let langRoutes = []

for (let i = 0; i < routes.length; i++)  {
  for (const locale of Object.keys(messages)) {
    const prefix = locale === 'en' ? '' : locale + '/'
    let route = {...routes[i]}
    route.path = '/' + prefix + route.path
    route.name = prefix + route.name
    route.meta = {
      ...routes[i].meta,
      locale: locale
    }

    langRoutes.push(route)
  }
}

export const router = {
  history: createWebHistory(process.env.BASE_URL || '/'),
  routes: langRoutes
}
