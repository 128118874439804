<template>
  <main class="u-bg-main-color u-second-color">
    <div class="intro__bg u-wrapper-panel">
      <video
        class="u-wrapper-panel u-fit-cover"
        :poster="data.image.length ? data.image[0].src : ''"
        autoplay="autoplay"
        loop="loop"
        muted="muted"
        playsinline
        v-if="videoId"
        :src="videoId"
      />
      <app-images v-else :images="data.image" class="u-wrapper-panel u-fit-cover" />
    </div>
    <div class="u-wrapper-panel u-flex u-column u-between u-pad-y-xl u-pad-x-lg u-z-back">
      <h1 class="u-full-width u-center u-marg-0">
        <img alt="Total Vivatech" class="u-inline-block u-w1of12 u-w2of12--md u-w4of12--sm" src="/images/logo.jpg">
      </h1>
      <div class="u-full-width">
        <div class="u-center">
          <h2 class="u-inline-block u-w10of12 t-h1">
            {{ data.title }}
          </h2>
        </div>
        <div class="u-center u-marg-t-sm">
          <h3 class="u-inline-block u-w6of12 u-w10of12--sm t-h4">
            {{ data.subtitle }}
          </h3>
        </div>
      </div>
      <div class="u-full-width u-center">
        <app-button
          v-if="firstKeypoint"
          :to="{name: 'Keypoint', params: {super: firstKeypoint.slug}}"
          @click="onStart"
        >
          Commencer
        </app-button>
      </div>
    </div>
  </main>
</template>

<script>
import { audio } from '@/utils/Audio'

export default {
  computed: {
    data () {
      return this.$store.getters['data/homepage']
    },
    firstKeypoint () {
      return this.$store.getters['data/keypoints'][0]
    },
    videoId () {
      if (!this.data.video.length) {
        return null
      }
      return this.$device.isMobile && this.data.video[1] ? this.data.video[1].url : this.data.video[0].url
    },
  },

  methods: {
    onStart () {
      this.$store.commit('data/restart')
      this.$ga.send('navigation', 'introduction', 'next')
      audio.src = this.$store.state.global.sounds.keypoint
      audio.play()
    }
  }
}  
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.intro
  &__bg:after
    content ''
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    background $colors.black
    opacity .6

</style>