<template>
  <main>
    <question v-if="hasQuestions" :data="data" />  
  </main>
</template>

<script>
import Question from '@/views/keypoint/Question'

export default {
  components: {
    Question
  },

  computed: {
    data () {
      return this.$store.getters['data/keypoint'](this.$route.params.super, this.$route.params.slug)
    },
    hasChildren () {
      return !!this.data?.keypoints?.length
    },
    hasQuestions () {
      return !!this.data?.questions?.length
    },
    type () {
      return this.data.questions.length ? 'question' : 'media'
    }
  }
}
</script>