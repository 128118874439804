export const assets = [
  {
    name: "env",
    url: "/test.glb"
  }
]

export const setup = {
  mainColor: "#ff0000",
  secondColor: "#00ff00"
}

export const scoring = {
  right: 10,
  wrong: -5
}

export const pages = [{
    name: 'about',
    model: 'stabout_stabout_Entry',
    type: 'stabout',
    active: true
  },
  {
    name: 'credits',
    model: 'stCredits_stCredits_Entry',
    type: 'stCredits',
    active: false
  },
  {
    name: 'cgu',
    model: 'stCGU_stCGU_Entry',
    type: 'stCGU',
    active: false
  },
  {
    name: 'personal',
    model: 'stPersonalData_stPersonalData_Entry',
    type: 'stPersonalData',
    active: false
  }
]