<template>
  <fieldset class="u-border-none u-marg-0 u-wrapper-panel u-row u-bottom u-center">
    <div class="u-wrapper-panel">
      <video
        class="u-wrapper-panel u-fit-cover"
        :poster="data.image.length ? data.image[0].src : ''"
        autoplay="autoplay"
        loop="loop"
        muted="muted"
        playsinline
        v-if="videoId"
        :src="videoId"
      />
    </div>
    <transition name="t-fader">
      <div v-if="!isQuestion && !$device.isDesktop" class="keypoint-question__title__wrapper u-row u-center t-h2 u-z-back">
        <h3 class="keypoint-question__title u-bg-main-color">
          {{ data.title }}
        </h3>
      </div>
    </transition>
    <div
      v-if="$device.isDesktop"
      class="keypoint-question__header u-z-back u-w4of12 u-row u-between u-middle u-pad-r-md u-pad-l-md--sm"
    >
      <app-button class="keypoint-question__header__btn" :class="{'is-visible': currentQuestion > 0}" @click="onPrevious" :icon="true">
        <svg class="u-marg-r-sm u-fill-white" preserveAspectRatio="xMidYMid meet">
          <use xlink:href="#arrow-left"/>
        </svg>
        Question précédente
      </app-button>
      <div v-if="currentQuestion !== -1 && data.questions[currentQuestion]" class="keypoint-question__header__nbr u-main-color t-cta t-bold">
        {{ data.questions[currentQuestion].globalIndex + 1 }} / {{ totalQuestions }}
      </div>
    </div>
    <template v-for="(question, i) in data.questions">
      <transition name="t-fader--delayed">
        <div
          v-if="(isQuestion || $device.isDesktop) && currentQuestion === i"
          class="keypoint-question u-z-back u-w4of12 u-w12of12--sm u-row u-top u-middle--md u-pad-y-md u-pad-r-md u-pad-l-md--sm"
        >
          <question :totalQuestions="data.questions.length" @answer="onAnswer" :data="question" />
        </div>
      </transition>
    </template>

    <transition name="t-fader">
      <div v-if="!isQuestion && !$device.isDesktop" class="u-row u-full-width u-center u-marg-b-md u-z-back">
        <app-button
          @click="$ga.send('navigation', 'restart')"
          :ghost="true"
          :main="true"
          :to="{name: 'Introduction'}"
          class="u-w5of12 u-marg-r-sm"
        >
          Recommencer
        </app-button>
        <app-button @click="isQuestion = true" class="u-w5of12">
          Voir questions
        </app-button>
      </div>
    </transition>
  </fieldset>
</template>

<script>
import Question from '@/components/Question'
import AppButton from '@/components/common/AppButton'

import { audio } from '@/utils/Audio'

export default {
  props: {
    data: {
      type: Object,
      default: () => {return {}}
    }
  },

  computed: {
    videoId () {
      if (!this.data.video.length) {
        return null
      }
      return this.$device.isMobile && this.data.video[1] ? this.data.video[1].url : this.data.video[0].url
    },
    totalQuestions () {
      return this.$store.getters['data/totalQuestions']
    }
  },

  data () {
    return {
      currentQuestion: -1,
      isQuestion: false
    }
  },

  components: {
    Question
  },

  mounted () {
    this.onInit()
    this.$ga.send('navigation', 'question', '', this.data.slug)
  },

  methods: {
    onInit () {
      this.isQuestion = false
      this.currentQuestion = 0
    },

    onPrevious () {
      this.currentQuestion--
    },

    onAnswer (answer) {
      this.$store.commit('data/completed', {
        keypoint: this.data,
        question: this.currentQuestion,
        answer: answer
      })

      this.currentQuestion++
    }
  },

  watch: {
    currentQuestion () {
      if (this.currentQuestion >= this.data.questions.length) {
        const next = this.$store.getters['data/nextKeypoint'](this.$route.params.super, this.$route.params.slug)
        if (next) {
          this.$router.push({ name: 'Keypoint', params: {super: next.slug }})
        }
        else {
          this.$router.push({ name: 'End' })
        }
      }
    },

    $route (from, to) {
      this.onInit()

      if (to.name === 'Keypoint') {
        audio.src = this.$store.state.global.sounds.keypoint
        audio.play()
      }
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/easings'
@import '~@/styles/settings/mixins/mq'

.keypoint-question
  position absolute
  right 0
  top 100px
  height 100%

  +mq($until: 'tablet')
    top 0

  &__header
    position absolute
    right 0
    top: $spacings.md
    
    &__btn
      opacity 0
      pointer-events none
      transition opacity .3s linear
      
      &.is-visible
        opacity 1
        pointer-events all

    &__nbr
      border solid 3px $colors.main-color
      padding 15px
      border-radius 100px

  &__title
    border-radius 2em
    padding .7em 1em

    &__wrapper
      position absolute
      left 0
      width 100%
      top $spacings.md

      +mq($until: 'tablet')
        top 20%
    
</style>