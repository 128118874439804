<template>
  <div
    id="main"
    ref="main"
    @mousemove="onMouseMove"
    :class="{'is-full': $route.meta.isFull}"
    class="u-full-width u-relative u-overflow-hidden u-antialiased"
    :style="{
      '--main-color': mainColor,
      '--second-color': secondColor,
      '--viewport-height': $device.height + 'px'
    }"
  >

    <symbols-svg />

    <template v-if="loaded">
      <router-view v-slot="{ Component }">
        <transition name="t-fader">
          <component :is="Component" />
        </transition>
      </router-view>
    </template>

    <bottom-links @page="onPage" />
    <app-header @navigation="isNavigation = true" @menu="isMenu = true" />

    <transition name="t-fader">
      <menu-mobile @navigation="isNavigation = true" @page="onPage" v-if="isMenu" @close="isMenu = false" />
    </transition>

    <transition name="t-fader">
      <base-page :name="currentPage" v-if="currentPage !== ''" @close="currentPage = ''" />
    </transition>

    <transition name="t-fader">
      <navigation v-if="isNavigation" @close="isNavigation = false" />
    </transition>

    <transition :duration="1400" name="transition">
      <transition-screen :duration="1300" v-if="transition" />
    </transition>

    <cookie />

  </div>
</template>

<script>
import App from './App'

import AppHeader from '@/components/common/AppHeader'
import BottomLinks from '@/components/common/BottomLinks'
import MenuMobile from '@/components/common/MenuMobile'
import TransitionScreen from '@/components/common/TransitionScreen'

import Cookie from '@/components/common/cookie'

import BasePage from '@/views/BasePage'
import Navigation from '@/views/Navigation'
import SymbolsSvg from '@/components/SymbolsSvg'

export default {
  name: 'App',

  mixins: [App],

  components: {
    SymbolsSvg,
    AppHeader,
    BottomLinks,
    Navigation,
    MenuMobile,
    Cookie,
    BasePage,
    TransitionScreen
  },

  data () {
    return {
      currentPage: '',
      isNavigation: false,
      isMenu: false,
      transition: true
    }
  },

  computed: {
    mainColor () {
      return '#4236FF'
    },
    secondColor () {
      return '#FFFFFF'
    },
    pages () {
      return this.$store.getters['data/pages']
    },
    loaded () {
      return this.$store.getters['data/globalLoaded'] && this.$store.getters['data/loaded']
    }
  },

  async created () {
    this.pointerevent = null

    this.$store.dispatch('data/global')
    this.$store.dispatch('data/keypoints')
  },

  mounted () {
    this.onResize()

    this.$hub.on('transition:on', () => {
      this.transition = true
    })

    this.$hub.on('transition:off', () => {
      this.transition = false
    })
  },

  methods: {
    onPage (name) {
      this.currentPage = name
    }
  },

  beforeUnmount () {
    this.controls.dispose()

    this.$refs.main.removeEventListener('pointerdown', this.controls.onPointerDown)
    this.$refs.main.removeEventListener('pointercancel', this.controls.onPointerCancel)
  }
}
</script>

<style lang="stylus" src="./styles/theme.styl"/>

<style lang="stylus">
// @import '~@/styles/settings/variables'

#main
  min-height 100vh

  &.is-full
    position fixed
    top .2px
    bottom .2px
    left 0
    right 0
    overflow hidden
    min-height auto

</style>