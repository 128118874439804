import Apollo from '@/store/interfaces/Apollo'
import gql from 'graphql-tag'
import { storage } from '@/utils/storage'

import { keypoint, endpage, homepage, basePage, config, categories } from './parts'

const apollo = new Apollo(process.env.VUE_APP_CRAFT_API, false)
apollo.setToken('Bearer ' + process.env.VUE_APP_CRAFT_TOKEN)
const graphqlClient = apollo.client

const site = process.env.VUE_APP_CRAFT_WEBSITE

class APICraft {

  getHomepage () {
    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
          query fetchHomepage {
            entry (site: "${site}", type: "sthomepage") {
              ... on sthomepage_sthomepage_Entry {
                ${homepage}
              }
            }
          }
        `
      })
      .then(res => {
        const result = res.data.entry

        resolve(result)
        storage.setItem(`fetchHomepage`, result)
      })
      .catch(err => {
        console.error('Error catching homepage ', err)
      })
    })
    const cache = storage.getItem(`fetchHomepage`)

    return {
      request,
      cache
    }
  }

  getEndpage () {
    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
          query fetchEndpage {
            entry (site: "${site}", type: "stendpage") {
              ... on stendpage_stendpage_Entry {
                ${endpage}
              }
            }
          }
        `
      })
      .then(res => {
        const result = res.data.entry

        resolve(result)
        storage.setItem(`fetchEndpage`, result)
      })
      .catch(err => {
        console.error('Error catching endpage ', err)
      })
    })
    const cache = storage.getItem(`fetchEndpage`)

    return {
      request,
      cache
    }
  }

  getPage (page) {
    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
          query fetchPage {
            entry (site: "${site}", type: "${page.type}") {
              ... on ${page.model} {
                ${basePage}
              }
            }
          }
        `
      })
      .then(res => {
        const result = res.data.entry

        resolve(result)
        storage.setItem(`fetchPage__${page.name}`, result)
      })
      .catch(err => {
        console.error(`Error catching page ${page.name} `, err)
      })
    })
    const cache = storage.getItem(`fetchPage__${page.name}`)

    return {
      request,
      cache
    }
  }

  getConfig () {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
          query fetchConfig {
            entry (site: "${site}", type: "stConfg") {
              ... on stConfg_stConfg_Entry {
                ${config}
              }
            }
          }
        `
      })
      .then(res => {
        const result = res.data.entry

        resolve(result)
        storage.setItem(`fetchConfig`, result)
      })
      .catch(err => {
        console.error('Error catching config ', err)
      })
    })
    const cache = storage.getItem(`fetchConfig`)

    return {
      request,
      cache
    }
  }

  getEntries () {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
          query fetchEntries {
            keypoints: entries (site: "${site}", type: "stKeypoint", level: 1) {
              ... on stKeypoint_stKeypoint_Entry {
                ${keypoint}
                keypoints: children {
                  ... on stKeypoint_stKeypoint_Entry {
                    ${keypoint}
                  }
                }
              }
            }
          }
        `
      })
      .then(res => {
        const result = res.data.keypoints

        resolve(result)
        storage.setItem( `fetchEntries`, result)
      })
      .catch(err => {
        console.error('Error catching entries ', err)
      })
    })
    const cache = storage.getItem( `fetchEntries`)

    return {
      request,
      cache
    }
  }

  getEntriesFiltered (categories = []) {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
          query fetchEntries ($categories: [QueryArgument]) {
            keypoints: entries (site: "${site}", type: "stKeypoint", stCategories: $categories) {
              ... on stKeypoint_stKeypoint_Entry {
                id
              }
            }
          }
        `,
        variables: { categories: categories }
      })
      .then(res => {
        const result = res.data.keypoints

        resolve(result)
        storage.setItem( `fetchEntries_${JSON.stringify(args)}`, result)
      })
      .catch(err => {
        console.error('Error catching entries ', err)
      })
    })
    const cache = storage.getItem( `fetchEntries_${JSON.stringify(args)}`)

    return {
      request,
      cache
    }
  }

  getJobFiltered (categories = []) {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
          query fetchJob ($categories: [QueryArgument]) {
            job: entry (site: "${site}", type: "workEntries", stCategories: $categories) {
              ... on totalWork_workEntries_Entry {
                id
                title
                shortTitle
                subtitle: sousTitre
                url
                video {
                  id
                  url
                }
                wysiwyg
              }
            }
          }
        `,
        variables: { categories: categories }
      })
      .then(res => {
        const result = res.data.job

        resolve(result)
        storage.setItem( `fetchEntries_${JSON.stringify(args)}`, result)
      })
      .catch(err => {
        console.error('Error catching entries ', err)
      })
    })
    const cache = storage.getItem( `fetchEntries_${JSON.stringify(args)}`)

    return {
      request,
      cache
    }
  }

  getCategories () {
    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
          query fetchCategories {
            categories (site: "${site}", group: "stAnswerCategory") {
              ... on stAnswerCategory_Category {
                ${categories}
              }
            }
          }
        `
      })
      .then(res => {
        const result = res.data.categories

        resolve(result)
        storage.setItem(`fetchCategories`, result)
      })
      .catch(err => {
        console.error('Error catching categories ', err)
      })
    })
    const cache = storage.getItem(`fetchCategories`)

    return {
      request,
      cache
    }
  }

  getCategoryCount (category = []) {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
          query fetchCategoryCount ($category: [QueryArgument]) {
            entryCount (site: "${site}", type: "stKeypoint", stCategories: $category)
          }
        `,
        variables: { category: category }
      })
      .then(res => {
        const result = res.data.entryCount

        resolve(result)
        storage.setItem( `fetchCategoryCount_${JSON.stringify(args)}`, result)
      })
      .catch(err => {
        console.error('Error catching entries ', err)
      })
    })
    const cache = storage.getItem( `fetchCategoryCount_${JSON.stringify(args)}`)

    return {
      request,
      cache
    }
  }

  imgSize ({id, width, height}) {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      let query = {}

      if (width && height) {
        query = {
          query: gql`
            query fetchImg ($id: [QueryArgument], $width: Int, $height: Int) {
              asset(id: $id) {
                ... on default_Asset {
                  url(width: $width, immediately: true, height: $height)
                }
              }
            }
          `,
          variables: { id, width, height }
        }
      }
      else if (width && !height) {
        query = {
          query: gql`
            query fetchImg ($id: [QueryArgument], $width: Int) {
              asset(id: $id) {
                ... on default_Asset {
                  url(width: $width, immediately: true)
                }
              }
            }
          `,
          variables: { id, width }
        }
      }
      else if (!width && height) {
        query = {
          query: gql`
            query fetchImg ($id: [QueryArgument], $height: Int) {
              asset(id: $id) {
                ... on default_Asset {
                  url(height: $height, immediately: true)
                }
              }
            }
          `,
          variables: { id, height }
        }
      }
      else {
        query = {
          query: gql`
            query fetchImg ($id: [QueryArgument])  {
              asset(id: $id) {
                ... on default_Asset {
                  url
                }
              }
            }
          `,
          variables: { id }
        }
      }

      graphqlClient.query(query)
        .then(res => {
          resolve(res.data.asset.url)

          const result = res.data.asset.url

          resolve(result)
          storage.setItem( `fetchImg_${JSON.stringify(args)}`, result)
        })
        .catch(err => {
          console.error('Error catching global params ', err)
        })
    })

    const cache = storage.getItem(`fetchImg_${JSON.stringify(args)}`)

    return {
      request,
      cache
    }
  }
}

export const api = new APICraft()