<template>
  <nav class="u-pad-y-sm u-pad-x-md u-wrapper-panel u-bg-main-color u-flex u-column u-between u-z-middle">
    <div class="u-full-width">
      <div class="u-row u-between u-middle">
        <div class="u-w3of12">
          <router-link title="homepage" class="header__logo u-block" :to="{name: 'Introduction'}">
            <img src="/images/logo.jpg" class="u-full-width u-block">
          </router-link>
        </div>
        <div class="u-w3of12 u-right">
          <app-button-round
            @click="$emit('close')"
            svg="cross"
            aria-label="close"
            :invert="true"
            :stroke="true"
          />
        </div>
      </div>
      <div class="u-pad-t-sm">
        <template v-for="page in pages">
          <div
            role="link"
            class="u-marg-t-md u-white t-h1 u-cursor-pointer"
            @click="onOpenPage(page.name)"
          >
            {{ page.title }}
          </div>
        </template>
      </div>
    </div>
    <div class="u-full-width">
      <div class="t-h1 u-white t-h1 u-marg-b-xs">
        Partager
      </div>
      <socials :button="true" display="between" />
    </div>
  </nav>
</template>

<script>
import AppButtonRound from '@/components/common/AppButtonRound'
import Socials from '@/components/common/Socials'

export default {
  components: {
    AppButtonRound,
    Socials
  },

  computed: {
    pages () {
      return this.$store.getters['data/pages']
    }
  },

  watch: {
    $route () {
      this.$emit('close')
    }
  },

  mounted () {
    this.$ga.send('navigation', 'menuMobile', 'open')
  },

  methods: {
    onOpenPage (name) {
      this.$emit('page', name)
      this.$emit('close')
    }
  },

  beforeUnmount () {
    this.$ga.send('navigation', 'menuMobile', 'close')
  }
}
</script>

<style lang="stylus">
.t-h1:focus
  text-decoration underline
</style>