<template>
  <div class="socials u-row u-middle u-left--sm" :class="'u-' + display">
    <app-button-round
      v-if="button"
      v-for="(social, index) in socials"
      :url="social.url"
      :ghost="true"
      :invert="true"
      :svg="social.icon"
      :title="social.name"
      @click="onClick(social.name)"
      class="socials__item"
      :class="{'u-marg-l-sm': index !== 0}"
    >
      <!-- <svg class="u-full-width u-full-height u-fill-white" preserveAspectRatio="xMidYMid slice">
        <use :xlink:href="'#' + social.icon"/>
      </svg> -->
    </app-button-round>
    <a
      v-else
      v-for="(social, index) in socials"
      :href="social.url"
      target="_blank"
      :title="social.name"
      class="socials__item socials__item--link"
      :class="{'u-marg-l-sm': index !== 0}"
      @click="onClick(social.name)"
    >
      <svg class="u-full-width u-full-height u-fill-main-color" preserveAspectRatio="xMidYMid slice">
        <use :xlink:href="'#' + social.icon"/>
      </svg>
    </a>
  </div>
</template>

<script>
import AppButtonRound from '@/components/common/AppButtonRound'
import CopyPaste from '@/utils/CopyPaste'

export default {
  props: {
    display: {
      type: String,
      default: 'evenly'
    },
    button: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onClick (name) {
      this.$ga.send('navigation', 'share', '', name)
      if (name === 'Link') {
        CopyPaste.makeCopy({values: window.location.origin})
      }
    }
  },

  components: {
    AppButtonRound
  },

  data () {
    return {
      socials: [
        {
          name: 'Facebook',
          icon: 'facebook',
          url: 'https://www.facebook.com/sharer/sharer.php?u=https://energyquiz.totalenergies.com/'
        },
        {
          name: 'Twitter',
          icon: 'twitter',
          url: `https://twitter.com/share?url=https://energyquiz.totalenergies.com/&text=${encodeURIComponent('TotalEnergies - Quel serait ton métier tech’ dans l\'Énergie ?.')}`
        },
        {
          name: 'Linkedin',
          icon: 'linkedin',
          url: 'https://linkedin.com/shareArticle/?url=https://energyquiz.totalenergies.com/'
        },
        // {
        //   name: 'Link',
        //   icon: 'link',
        // }
      ]
    }
  }
}
  
</script>

<style lang="stylus">
.socials
  &__item
    &--link
      width 1.2em
      height 1.2em
</style>