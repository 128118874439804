<template>
  <div class="result u-wrapper-panel u-row u-bottom u-center">
    <div class="u-wrapper-panel">
      <video
        class="u-wrapper-panel u-fit-cover"
        autoplay="autoplay"
        loop="loop"
        muted="muted"
        playsinline
        v-if="videoId"
        :src="videoId"
      />
    </div>
    <transition name="t-fader">
      <div v-if="!$device.isDesktop && !isDetails" class="result__title__wrapper u-center u-z-back">
        <div class="t-h3 u-main-color">
          Tu pourrais bien être...
        </div>
        <div class="u-center u-marg-t-sm u-pad-x-md">
          <h2 class="result__title t-h2 u-bg-main-color u-inline-block">
            {{ data.shortTitle ? data.shortTitle : data.title }}
          </h2>
        </div>
      </div>
    </transition>
    <transition name="t-fader">
      <div v-if="!isDetails && !$device.isDesktop" class="u-row u-full-width u-center u-marg-b-md">
        <app-button
          @click="$ga.send('navigation', 'end', 'offers')"
          :icon="true"
          :second="true"
          v-if="data.CTAs[0]"
          :url="data.CTAs[0].url"
          class="u-marg-r-sm"
        >
          <svg class="u-marg-r-sm u-fill-main-color" preserveAspectRatio="xMidYMid meet">
            <use xlink:href="#external"/>
          </svg>
          {{ data.CTAs[0].text }}
        </app-button>
        <app-button @click="onDetails">
          VOIR DÉTAIL
        </app-button>
      </div>
    </transition>

    <!-- MOBILE VERSION -->
    <transition name="t-fader--delayed">
      <app-popin
        innerClass="result__popin u-main-color u-radius u-pad-md u-row u-middle u-relative u-overflow-y-scroll"
        v-if="isDetails && !$device.isDesktop"
        :big="true"
      >
        <div @click="isDetails = false" class="result__popin__close">
          <svg class="u-wrapper-panel u-stroke-main-color">
            <use xlink:href="#cross"/>
          </svg>
        </div>
        <h2 class="t-h1 u-full-width">
          {{ data.title }}
        </h2>
        <h3 class="t-h3 u-full-width u-marg-t-sm">
          {{ data.subtitle }}
        </h3>
        <!-- <div class="t-h4 u-full-width t-bold u-marg-t-sm t-wysiwyg" v-html="data.wysiwyg" /> -->
        <strates-category :data="data.content" />
      </app-popin>
    </transition>

    <!-- DESKTOP VERSION -->
    <div v-if="$device.isDesktop">
      <div class="result__panel u-z-back u-w4of12 u-w12of12--sm u-row u-top u-pad-y-md u-pad-r-md u-pad-l-md--sm">
        <div class="result__panel__item u-radius u-main-color u-invisible-scroll u-pad-md u-left">
          <h2 class="t-h2 u-full-width">
            {{ data.title }}
          </h2>
          <!-- <div class="t-h4 u-full-width t-bold u-marg-t-sm t-wysiwyg" v-html="data.wysiwyg" /> -->
          <strates-category :data="data.content" />
          <div class="result__panel__item__bottom u-row u-middle u-between u-radius">
            <app-button v-if="data.CTAs[0]" :ghost="true" :main="true" :icon="true" :url="data.CTAs[0].url">
              <svg class="u-marg-r-sm u-fill-main-color" preserveAspectRatio="xMidYMid meet">
                <use xlink:href="#external"/>
              </svg>
              {{ data.CTAs[0].text }}
            </app-button>
            <app-button v-if="data.CTAs[1]" :url="data.CTAs[1].url">
              {{ data.CTAs[1].text }}
            </app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppPopin from '@/components/common/AppPopin'
import AppButton from '@/components/common/AppButton'
import StratesCategory from '@/components/StratesCategory'

import { audio } from '@/utils/Audio'

export default {
  components: {
    AppPopin,
    AppButton,
    StratesCategory
  },

  computed: {
    data () {
      return this.$store.getters['data/mostChosenCategory']
    },
    videoId () {
      if (!this.data || !this.data.video.length) {
        return null
      }
      return this.$device.isMobile && this.data.video[1] ? this.data.video[1].url : this.data.video[0].url
    }
  },

  data () {
    return {
      isDetails: false
    }
  },

  mounted () {
    this.$ga.send('navigation', 'end')
    this.$ga.send('gameplay', 'result', '', this.data.slug)


      audio.src = this.$store.state.global.sounds.result
      audio.play()
  },

  methods: {
    onDetails () {
      this.$ga.send('navigation', 'end', 'details')
      this.isDetails = true
    }
  },

  watch: {
    isDetails (to) {
      if (to) {
        this.$hub.emit('header:hidden')
      }
      else {
        this.$hub.emit('header:visible')
      }
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.result
  &__panel    
    position absolute
    right 0
    top 100px
    bottom 100px
    height 100%

    &__item
      background-color: rgba(#4236FF, .2)
      position relative
      padding-bottom 120px !important
      
      &__bottom
        position absolute
        width 100%
        height 100px
        left 0
        bottom 0
        padding: 0 $spacings.md
        background-color: rgba(#4236FF, .1)

  &__popin
    max-height 100%
    background-color: rgba($colors.white, .85)

    &__close
      position absolute
      top 15px
      right 15px
      height 17px
      width 17px

  &__title
    border-radius 2em
    padding .7em 1em

    &__wrapper
      position absolute
      left 0
      top 20%
      width 100%
</style>