<template>
  <div>
    <transition name="t-fader">
      <template v-if="$device.isDesktop && $route.name !== 'Introduction'">
        <div class="bottom-links u-row u-between t-h4 u-main-color u-z-back">
          <div class="u-row u-left u-w8of12">
            <div
              v-for="page in pages"
              @click="$emit('page', page.name)"
              role="link"
              class="t-h4 t-link u-uppercase t-bold u-cursor-pointer u-marg-r-md"
            >
               {{ page.title }}
            </div>
          </div>
          <socials />
        </div>
      </template>
    </transition>
  </div>
</template>

<script>
import Socials from '@/components/common/Socials'
import AppButtonRound from '@/components/common/AppButtonRound'
import AppPopin from '@/components/common/AppPopin'
import Categories from '@/components/Categories'

export default {
  computed: {
    pages () {
      return this.$store.getters['data/pages']
    }
  },

  data () {
    return {
      isFilter: false
    }
  },

  components: {
    Socials,
    AppPopin,
    AppButtonRound,
    Categories
  },

  watch: {
    $route () {
      this.isFilter = false
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.bottom-links
  position absolute
  bottom $spacings.md
  left $spacings.md
  right $spacings.md
</style>